<template>
    <el-dialog
        title="用户信息编辑"
        :visible.sync="dialogVisible"
        width="600px"
        :append-to-body="true"
        :modal-append-to-body="true"
        :close-on-press-escape="false"
        :wrapperClosable="false"
        class="custom-dialog"
    >
        <el-form ref="editFormRef" :model="form" label-width="120px" style="width:95%">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="手机号">
                        <el-input v-model="form.tel" placeholder="请填写身份证号码" />
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="真实姓名">
                        <el-input v-model="form.realName" placeholder="请填写电话号码" />
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="身份证号码">
                        <el-input v-model="form.idCard" placeholder="请填写电话号码" />
                    </el-form-item>
                </el-col> 
                <el-col :span="24">
                    <el-form-item label="用户地址">
                        <el-input v-model="form.address" placeholder="请填写电话号码" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item>
                <el-button size="small" @click="closeDialog">取消</el-button>
                <el-button 
                    type="primary" 
                    size="small" 
                    :loading="confirmLoading" 
                    :confirmDisabled="confirmDisabled" 
                    @click="handleConfirm('editFormRef')"
                >确定</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import { api_editMember } from '@/api/member';

export default {
    name: 'ImportQuestion',

    prop: {
        updateFn: {
            type: Function
        }
    },

    data() {
        return {
            dialogVisible: true,

            form: {

            },

            confirmLoading: false,
            confirmDisabled: false
        };
    },

    mounted() {
        this.$eventBus.$on('OPEN_MEMBER_EDIT_DIALOG', (data) => {
            const { userId, realName, tel, idCard, address } = data;
            this.form = {
                userId, 
                realName, 
                tel, 
                idCard,
                address
            };
            this.openDialog();
        });
    },

    methods: {
        openDialog() {
            this.dialogVisible = true;
        },

        closeDialog() {
            this.dialogVisible = false;
        },

        handleConfirm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.confirmDisabled = true;
                    this.confirmLoading = true;

                    api_editMember(this.form).then(res => {
                        this.confirmDisabled = false;
                        this.confirmLoading = false;
                        this.$message({
                            type: 'success',
                            message: '操作成功！'
                        });

                        this.closeDialog();
                        
                        this.$emit('updateFn');                 
                    }).catch(() => {
                        this.confirmDisabled = false;
                        this.confirmLoading = false;
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-dialog {
    .drag-upload {    
        ::v-deep.el-upload {
            display: block;
        }
        ::v-deep.el-upload-dragger {
            width: 100%;
            height: 200px;
        }
    }

    .el-drawer__footer {
        margin-top: 20px;
        text-align: center;
    }
}
</style>
