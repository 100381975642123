<template>
    <Layout title="会员列表">        
        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-search"></i>
                <span class="title">筛选条件</span>
            </template>
            <el-form :model="queryForm" inline ref="queryFormRef" size="small">
                <el-form-item label="手机号">
                    <el-input v-model="queryForm.tel" clearable />
                </el-form-item>
                <el-form-item label="昵称">
                    <el-input v-model="queryForm.userName" />
                </el-form-item>
                <el-form-item label="性别" prop="gender">
                    <el-select v-model="queryForm.gender" placeholder="请选择性别">
                        <el-option label="全部" value="" />
                        <el-option label="男" :value="1" />
                        <el-option label="女" :value="2" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="handleQuery">确定</el-button>
                    <el-button type="default" icon="el-icon-refresh-left" @click="handleReset('queryFormRef')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-s-grid"></i>
                <span class="title">数据列表</span>
            </template>

            <el-table :data="tableData" v-loading="loading">
                <el-table-column prop="userId" label="ID" width="50"></el-table-column>               
                <el-table-column label="头像" width="120" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.avatar" width="80" height="80" />
                    </template>
                </el-table-column>
                <el-table-column prop="userName" label="昵称" width="180"></el-table-column> 
                <el-table-column prop="tel" label="手机号"></el-table-column> 
                <el-table-column prop="realName" label="真实姓名"></el-table-column>
                <el-table-column label="性别">
                    <template slot-scope="{ row }">
                        <el-tag :type="row.gender === 0 ? 'success' : 'primary'">{{ row.gender === 0 ? '女' : '男' }}</el-tag>
                    </template>
                </el-table-column> 
                <el-table-column prop="statusString" label="状态"></el-table-column>     
                <el-table-column prop="lastLoginTime" label="最后登录时间"></el-table-column>   
                <el-table-column fixed="right" label="操作" width="240" align="center">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="primary" size="mini" icon="el-icon-view" @click.native="handView(scope.row)">查看</el-button>
                            <el-button type="primary" size="mini" icon="el-icon-edit" @click.native="handleEdit(scope.row)">编辑</el-button>
                            <el-button type="danger" size="mini" icon="el-icon-delete" @click.native="handleRemove(scope.row)">删除</el-button>  
                        </el-button-group>                                             
                    </template>
                </el-table-column>
            </el-table>

            <div class="el-card__footer">
                <div></div>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :page-count="totalPage"
                    @current-change="handleCurrentPageChange"
                ></el-pagination> 
            </div>
        </el-card>

        <!-- 查看用户信息弹窗 -->
        <el-dialog
            title="查看用户信息"
            :visible.sync="dialogVisible"
            width="65%"
            class="custom-dialog"
        >
            <div style="min-height:500px">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="基本信息" name="first">
                        <Basic :userId="userId" />
                    </el-tab-pane>
                    <el-tab-pane label="教育经历" name="second" lazy>
                        <Education :userId="userId" />
                    </el-tab-pane>
                    <el-tab-pane label="工作经历" name="third" lazy>
                        <Job :userId="userId" />
                    </el-tab-pane>
                    <el-tab-pane label="培训经历" name="fourth" lazy>
                        <Train :userId="userId" />
                    </el-tab-pane>
                    <el-tab-pane label="证书" name="five" lazy>
                        <Certificate :userId="userId" />
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-dialog>

        <EditDialog v-if="isLoadEditDialog" @updateFn="getData" />
    </Layout>
</template>

<script>
import { getMemberListApi, deleteUserApi } from '@/api/member';

import Basic from './components/Basic.vue';
import Education from './components/Education.vue';
import Job from './components/Job.vue';
import Train from './components/Train.vue';
import Certificate from './components/Certificate.vue';
import EditDialog from './components/Edit.vue';

export default {
    components: {
        Basic,
        Education,
        Job,
        Train,
        Certificate,
        EditDialog
    },

    data() {
        return {
            queryForm: {
                pageSize: 10,
                currentPage: 1,
                name: ''
            },

            loading: false,

            tableData: [],
            totalPage: 1,

            dialogVisible: false,
            userId: '',
            activeName: 'first',

            isLoadEditDialog: false
        };
    },

    methods: {
        // 获取数据
        getData() {
            getMemberListApi(this.queryForm).then(res => {
                if (res.data) {
                    const { allNum, listData } = res.data;

                    this.tableData = listData;
                    this.totalPage = Math.ceil(allNum / this.queryForm.pageSize);
                }
            });
        },

        // 分页
        handleCurrentPageChange(e) {
            this.queryForm.currentPage = e;
            this.getData();
        },

        // 搜索
        handleQuery() {
            this.getData();
        },

        // 重置
        handleReset(formName) {
            console.log(formName);
            this.$refs[formName].resetFields();
        },

        // 查看
        handView(rowData) {
            const { userId } = rowData;
            this.userId = userId;
            this.dialogVisible = true;
        },

        // 编辑
        handleEdit(rowData) {
            this.isLoadEditDialog = true;
            setTimeout(() => {
                this.$eventBus.$emit('OPEN_MEMBER_EDIT_DIALOG', rowData);
            });
        },

        // 删除
        handleRemove(rowData) {
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const { userId } = rowData;
                deleteUserApi({ userId }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    }); 

                    this.getData();
                });                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        }
    },

    mounted() {
        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.custom-card__outer {
    .custom-toolbar {
        display: flex;
        align-items: center;
        ::v-deep .el-button {
            margin-right: 20px;
            margin-left: 0;
        }
        .info {
            font-size: 30px;
            color: #f56c6c;
        }
    }
}
</style>
