/**
 * 积分管理 api 定义
 */

import $http from '@/plugins/axios';

// 获取用户列表
export const getMemberListApi = (params) => {
    return $http({
        url: '/admin/getAllUser.html',
        method: 'GET',
        params
    });
};

// 获取用户基础数据
export const getMemberBasicDataApi = (params) => {
    return $http({
        url: '/admin/getUserBaseInfo.html',
        method: 'GET',
        params
    });
};

// 获取教育经历
export const getMemberEducationListApi = (params) => {
    return $http({
        url: '/admin/getUserEducation.html',
        method: 'GET',
        params
    });
};

// 获取工作经历
export const getMemberJobListApi = (params) => {
    return $http({
        url: '/admin/getUserJob.html',
        method: 'GET',
        params
    });
};

// 获取培训经历
export const getMemberTrainListApi = (params) => {
    return $http({
        url: '/admin/getUserTrain.html',
        method: 'GET',
        params
    });
};

// 获取用户证书
export const getMemberCertificateDataApi = (params) => {
    return $http({
        url: '/admin/getUserCertificate.html',
        method: 'GET',
        params
    });
};

// 删除用户
export const deleteUserApi = (params) => {
    return $http({
        url: '/admin/delUser.html',
        method: 'GET',
        params
    });
};

// 获取用户列表
export const api_editMember = (data) => {
    return $http({
        url: '/admin/updateUserInfo.html',
        method: 'POST',
        data
    });
};